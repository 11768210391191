<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-text class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Історія зміни власника помешкання
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card-text>
    <v-data-table
        :headers="ownerChangesHeaders"
        :items="owner_changes"
        hide-default-footer
        class="custom-table custom-table-1"
    >
      <template v-slot:item.icon>
        <v-icon class="mr-2" size="26" color="success">
          mdi-account-alert
        </v-icon>
      </template>
      <template v-slot:item.create_date="{ item }">
        {{ item.create_date | formatDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {registrationType} from "@/utils/icons";
import {GET_OWNER_CHANGES} from "@/store/actions/family";

export default {
  name: "HWP_Owner_Changes",
  computed: {
    ...mapGetters({
      owner_changes: 'getOwnerChanges'
    })
  },
  props: {
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      registrationType,
      ownerChangesHeaders: [
        { text: '', value: 'icon', width: 60 },
        { text: 'Дата', value: 'create_date' },
        { text: 'Попередній', value: 'previous_owner' },
        { text: 'Теперішній', value: 'current_owner' },
        { text: 'Автор', value: 'owner' },
      ],
      family_member_modal_id: ""
    }
  },
  methods: {
    ...mapActions({
      fetchOwnerChanges: GET_OWNER_CHANGES,
    }),
    openFamilyMemberModal(row) {
      this.family_member_modal_id = row.person_hash
    },
    familyMemberUpdateItemModal() {
      this.family_member_modal_id = ""
    }
  },
  created() {
    let person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchOwnerChanges(person_hash)
    }
  }
}
</script>

<style scoped lang="scss">

</style>